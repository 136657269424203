import {
   AccordionProps,
   Badge,
   Box,
   Button,
   Flex,
   Skeleton,
   Text,
   Tooltip,
} from '@chakra-ui/react';
import {
   AppointmentItem,
   TimetapLocationType,
   WelkinEncounterTemplateNames,
   WelkinPrograms,
} from '@innerwell/dtos';
import {
   getAppointmentReschedulePath,
   getGoogleMapsDirectionsUrl,
} from '@innerwell/utils';
import { useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { useRouter } from 'next/navigation';
import React from 'react';
import { isIOS } from 'react-device-detect';

import { useAppointment } from '@/hooks/react-query/useAppointment';
import { useAppointmentLoadingStatus } from '@/hooks/useAppointmentLoadingStatus';
import useThemedToast from '@/hooks/useThemedToast';

import { SkeletonWithLoader } from '@/components/Loaders';
import { NextLink } from '@/components/NextLink/NextLink';
import { NextLinkButton } from '@/components/NextLinkButton/NextLinkButton';

import { webApiClient } from '@/api-client/apiClient';
import { usePatientProgram } from '@/contexts/patient-program-context';
import {
   USER_CAN_JOIN_MEET_BEFORE_MINUTES,
   ZOOM_APP_DOWNLOAD_LINK_ANDROID,
   ZOOM_APP_DOWNLOAD_LINK_IOS,
} from '@/utils/consts';
import {
   formatClinician,
   formatDateNicely,
   formatTimeRange,
   getTimezone,
} from '@/utils/formatting';

import { Card } from './components';
import CustomAvatar from '../Avatar/Avatar';
import { Icon } from '../Icon';

import { queryKeys } from '@/types/query-keys';

interface IProps extends AccordionProps {
   title: string;
   appointment: AppointmentItem;
   withAccordion?: boolean;
   disableJoinButton?: boolean;
   linkRescheduleToSupport?: boolean;
   size?: 'small' | 'large';
}

export const AppointmentWithRescheduleCard: React.FC<IProps> = ({
   title,
   appointment,
   withAccordion = false,
   disableJoinButton,
   linkRescheduleToSupport = false,
   size = 'large',
   ...props
}) => {
   const newWindowRef = React.useRef<Window | null>(null);
   const {
      programPhase: { program },
   } = usePatientProgram();

   const appointmentLoadingStatus = useAppointmentLoadingStatus();

   const { toastError } = useThemedToast();
   const { push } = useRouter();
   const [minutesUntilMeet, setMinutesUntilMeet] = React.useState(1);
   const [minutesAfterMeet, setminutesAfterMeet] = React.useState(1);

   React.useEffect(() => {
      const minutesStartDiff = DateTime.fromISO(appointment.startDateTime)
         .diff(DateTime.now(), 'minutes')
         .toObject().minutes;

      const minutesEndDiff = DateTime.now()
         .diff(DateTime.fromISO(appointment.endDateTime), 'minutes')
         .toObject().minutes;

      setMinutesUntilMeet(minutesStartDiff || 0);
      setminutesAfterMeet(minutesEndDiff || 0);
   }, [appointment.startDateTime, appointment.endDateTime]);

   const clinician = formatClinician(appointment.clinician);

   const canReschedule =
      DateTime.now() < DateTime.fromISO(appointment.patientReschedulableUntil);

   const [index, setIndex] = React.useState<number[]>([]);

   const handleIndexChange = (index: number | number[]) => {
      setIndex(!Array.isArray(index) ? [index] : index);
   };

   const appointmentJoinUrlQuery = useQuery({
      queryKey: queryKeys.appointmentDisposition(appointment.id),
      queryFn: async () => {
         const response = await webApiClient.appointments.getJoinUrl({
            params: {
               id: appointment.id,
            },
         });

         const url = response.body;

         const getMeetingNumber = () => {
            if (url) {
               const m = url.match(/\/j\/(\d+)$/);
               return {
                  meetingUrl: url,
                  meetingNumber: m?.[1],
               };
            }

            return {
               meetingNumber: clinician.zoomId,
               fromClinician: true,
            };
         };

         const { meetingNumber, fromClinician, meetingUrl } =
            getMeetingNumber();

         if (!meetingNumber) {
            toastError(
               'Cannot start meeting',
               'No Zoom meeting number available',
            );
            return;
         }

         if (fromClinician) {
            push(`/meeting/${meetingNumber}?pw=innerwell`);
         } else if (meetingUrl && newWindowRef.current) {
            newWindowRef.current.location = meetingUrl;
         } else {
            toastError(
               'An error occurred, please try again or contact the support team',
            );
         }

         return url;
      },
      enabled: false,
   });

   const handleOpenMeeting = () => {
      // We first open the window/tab and then apply the link to the opened tab
      newWindowRef.current = window.open();

      appointmentJoinUrlQuery.refetch();
   };

   React.useEffect(() => {
      if (withAccordion) {
         setIndex([]);
      } else {
         setIndex([0]);
      }
   }, [withAccordion]);

   const { appointment: appointmentData, isLoading: isAppointmentDataLoading } =
      useAppointment({ appointmentId: appointment.id });

   const meetingLocationType = appointmentData?.location.locationType;

   // If its eedical consult and 5 minutes passed since the end of the meeting
   // Show new card
   if (
      program === WelkinPrograms.Intake &&
      appointment.appointmentTemplate ===
         WelkinEncounterTemplateNames.MedicalConsult &&
      minutesAfterMeet > 5
   ) {
      return (
         <Card mt={4}>
            <Card.Title>Medical Review in Progress</Card.Title>
            <Card.Text>
               Please give us 1-2 business days to review your case notes and
               confirm treatment eligibility. Once confirmed, we’ll be in touch
               when your prescription is on the way.
            </Card.Text>
            <Card.Image
               src="/images/medical-consult-woman.svg"
               alt="Background image"
            />
         </Card>
      );
   }

   const cannotRescheduleButMeetingNotStarted =
      !canReschedule && minutesUntilMeet > USER_CAN_JOIN_MEET_BEFORE_MINUTES;

   const isAllLoaded =
      appointment.id !== appointmentLoadingStatus?.appointmentId &&
      !isAppointmentDataLoading;

   return (
      <SkeletonWithLoader
         loadingText="Loading upcoming appointments..."
         isLoaded={isAllLoaded}
         borderRadius="12px"
         minH={!isAllLoaded ? '275px' : 'auto'}
         display="flex"
         flexDir="column"
      >
         {isAllLoaded && (
            <Box
               bg="linear-gradient(216.83deg, rgba(255, 156, 75, 0.12) 30.52%, rgba(255, 156, 75, 0) 91.09%), #FFFFFF"
               borderRadius={12}
               index={index}
               onChange={handleIndexChange}
               h="full"
               {...props}
            >
               <Box
                  border="none"
                  color="white"
                  boxShadow="0px 5px 5px rgba(0, 0, 0, 0.15)"
                  borderRadius="12px"
                  h="full"
               >
                  <Box
                     h="full"
                     sx={{
                        '.chakra-collapse': {
                           h: 'full !important',
                        },
                     }}
                  >
                     <Flex p={0} h="full" flex={1}>
                        <Box
                           bg="linear-gradient(216.83deg, rgba(255, 156, 75, 0.12) 30.52%, rgba(255, 156, 75, 0) 91.09%), #FFFFFF"
                           borderRadius={12}
                           overflow="hidden"
                           h="full"
                        >
                           <Flex h="full" flex={1}>
                              <Flex
                                 flex={{ base: 1 }}
                                 direction="column"
                                 m={{
                                    base: 5,
                                    lg: size === 'large' ? 10 : 3,
                                 }}
                                 mt={{
                                    base: 2,
                                    lg: size === 'small' ? 0 : 5,
                                 }}
                              >
                                 <Flex
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    mb={4}
                                    mt={withAccordion ? 0 : 3}
                                 >
                                    <Text
                                       size="leadText"
                                       fontWeight={500}
                                       color="text.primary"
                                    >
                                       {title}
                                    </Text>

                                    {DateTime.fromISO(
                                       appointment.startDateTime,
                                    ).toISODate() ===
                                    DateTime.now().toISODate() ? (
                                       <Badge
                                          bg="accent.peach"
                                          color="accent.orange"
                                          rounded="full"
                                          fontWeight={600}
                                          fontSize="xxs"
                                          ml={2}
                                       >
                                          Today
                                       </Badge>
                                    ) : null}
                                 </Flex>

                                 <Flex
                                    gap={{
                                       base: 3,
                                       lg: size === 'large' ? 8 : 3,
                                    }}
                                    mr={{
                                       lg: size === 'small' ? 4 : 0,
                                    }}
                                    alignItems="flex-start"
                                 >
                                    <Flex pos="relative">
                                       <CustomAvatar
                                          url={clinician.avatar}
                                          size="sm"
                                          desktopSize={
                                             size === 'large' ? 'md' : 'sm'
                                          }
                                          variant="square"
                                       />
                                       {meetingLocationType ===
                                       TimetapLocationType.Physical ? (
                                          <Icon
                                             name="in-person-badge"
                                             boxSize={{ base: 7, lg: 8 }}
                                             pos="absolute"
                                             right={-2}
                                             bottom={-2}
                                             color="accent.green"
                                          />
                                       ) : null}
                                    </Flex>

                                    <Flex
                                       direction="column"
                                       color="text.primary"
                                       justifyContent="flex-start"
                                       alignItems="flex-start"
                                    >
                                       <Skeleton
                                          isLoaded={Boolean(appointmentData)}
                                          borderRadius="12px"
                                          mb={{
                                             base: 3,
                                             lg: 2,
                                          }}
                                       >
                                          <Text
                                             lineHeight={1.3}
                                             fontWeight="600"
                                             fontSize={{
                                                base: 'md',
                                                lg:
                                                   size === 'large'
                                                      ? 'lg'
                                                      : 'md',
                                             }}
                                          >
                                             {`${
                                                meetingLocationType ===
                                                TimetapLocationType.Physical
                                                   ? 'In-office visit'
                                                   : 'Video Consult'
                                             } with ${clinician.fullName}`}
                                          </Text>
                                       </Skeleton>
                                       {meetingLocationType ===
                                       TimetapLocationType.Physical ? (
                                          <Badge
                                             mt={-2}
                                             mb={1}
                                             whiteSpace="pre-wrap"
                                          >
                                             {
                                                appointmentData?.location
                                                   .locationName
                                             }
                                          </Badge>
                                       ) : null}
                                       <Text fontSize="md">
                                          {formatDateNicely(
                                             appointment.startDateTime,
                                             { includeYear: false },
                                          )}
                                       </Text>
                                       <Text fontSize="md">
                                          {`${formatTimeRange({
                                             from: appointment.startDateTime,
                                             to: appointment.endDateTime,
                                          })} ${getTimezone()}`}
                                       </Text>
                                    </Flex>
                                 </Flex>
                                 <Flex
                                    pt={3}
                                    maxW={{
                                       lg: size === 'large' ? '340px' : '100%',
                                    }}
                                    flexDir="column"
                                    alignItems="flex-start"
                                    ml={{
                                       lg: size === 'large' ? '140px' : 0,
                                    }}
                                    mt={{
                                       lg:
                                          size === 'large'
                                             ? meetingLocationType ===
                                               TimetapLocationType.Physical
                                                ? '0'
                                                : '-10px'
                                             : 'auto',
                                    }}
                                    mb={{ base: 6, lg: 0 }}
                                 >
                                    {(canReschedule ||
                                       cannotRescheduleButMeetingNotStarted) && (
                                       <>
                                          {canReschedule ? (
                                             <NextLinkButton
                                                href={
                                                   linkRescheduleToSupport
                                                      ? '/support'
                                                      : getAppointmentReschedulePath(
                                                           appointment.id,
                                                        )
                                                }
                                                size="xs"
                                                w={
                                                   size === 'small'
                                                      ? '100%'
                                                      : 'auto'
                                                }
                                             >
                                                Reschedule
                                             </NextLinkButton>
                                          ) : (
                                             <Flex
                                                gap={{ base: 2, lg: 1 }}
                                                alignItems="center"
                                             >
                                                {meetingLocationType ===
                                                TimetapLocationType.Virtual ? (
                                                   <Tooltip
                                                      textColor="white"
                                                      label="Early access to the call will be available 15 minutes before its scheduled start time."
                                                      shouldWrapChildren
                                                      hasArrow
                                                      bg="text.primary"
                                                      borderRadius="8px"
                                                      maxW="210px"
                                                      py={2}
                                                      ml={{ base: 2, lg: 0 }}
                                                      px={{ base: 2, lg: 3 }}
                                                      fontWeight={500}
                                                   >
                                                      <Button
                                                         size="xs"
                                                         isDisabled
                                                      >
                                                         Join now
                                                      </Button>
                                                   </Tooltip>
                                                ) : appointmentData?.location
                                                     .description ? (
                                                   <NextLinkButton
                                                      href={getGoogleMapsDirectionsUrl(
                                                         appointmentData
                                                            .location
                                                            .description,
                                                      )}
                                                      size="xs"
                                                      target="_blank"
                                                   >
                                                      Get directions
                                                   </NextLinkButton>
                                                ) : (
                                                   <Button
                                                      size="xs"
                                                      minW="150px"
                                                      isLoading
                                                   />
                                                )}

                                                <NextLinkButton
                                                   href="/support"
                                                   size="xs"
                                                   fontSize="xs"
                                                   variant="link"
                                                   px={2}
                                                >
                                                   Contact support
                                                </NextLinkButton>
                                             </Flex>
                                          )}
                                       </>
                                    )}

                                    {!canReschedule &&
                                       !cannotRescheduleButMeetingNotStarted && (
                                          <>
                                             {!appointmentData ? (
                                                <Button size="xs" isLoading />
                                             ) : meetingLocationType ===
                                               TimetapLocationType.Virtual ? (
                                                <Button
                                                   as="a"
                                                   cursor="pointer"
                                                   size="xs"
                                                   rightIcon={
                                                      <Icon
                                                         name="arrow-right"
                                                         w={6}
                                                         h={6}
                                                      />
                                                   }
                                                   disabled={
                                                      disableJoinButton ||
                                                      appointmentJoinUrlQuery.isFetching
                                                   }
                                                   onClick={() => {
                                                      handleOpenMeeting();
                                                   }}
                                                   w={
                                                      size === 'small'
                                                         ? '100%'
                                                         : 'auto'
                                                   }
                                                >
                                                   Join now
                                                </Button>
                                             ) : (
                                                <NextLinkButton
                                                   href={getGoogleMapsDirectionsUrl(
                                                      appointmentData.location
                                                         .locationName,
                                                   )}
                                                   size="xs"
                                                   target="_blank"
                                                >
                                                   Get directions
                                                </NextLinkButton>
                                             )}
                                          </>
                                       )}
                                 </Flex>
                                 <Flex
                                    display={{ lg: 'none' }}
                                    w="full"
                                    fontWeight={500}
                                    color="background.fourth"
                                    borderRadius="12px"
                                    fontSize="xxs"
                                 >
                                    Please install the Zoom app in advance of
                                    joining your appointment.{' '}
                                    <NextLink
                                       href={
                                          isIOS
                                             ? ZOOM_APP_DOWNLOAD_LINK_IOS
                                             : ZOOM_APP_DOWNLOAD_LINK_ANDROID
                                       }
                                    >
                                       Download here
                                    </NextLink>
                                    .
                                 </Flex>
                              </Flex>
                           </Flex>
                        </Box>
                     </Flex>
                  </Box>
               </Box>
            </Box>
         )}
      </SkeletonWithLoader>
   );
};
